import { Injectable, computed, signal } from '@angular/core';
import { i18nRoutes } from '../../../assets/i18n/routes.i18n';
import { AppLanguage } from '../enums/app-language.enum';
import { PageId } from '../enums/page-id.enum';

export class Url {
  private start!: string | null;
  private middle!: string | null;
  private language!: AppLanguage;
  private page!: { id: PageId; path: string } | null;
  private ticketName!: string | null;

  constructor(private readonly url: string) {
    this.initUrl(url);
  }

  public getUrl(): string {
    return this.url;
  }

  public getLanguage(): AppLanguage {
    return this.language;
  }

  public getUrlPage(): { id: PageId; path: string } | null {
    return this.page;
  }

  public getPageId(): PageId | null {
    return this.page?.id || null;
  }
  public getPath(): string | null {
    return this.page?.path || null;
  }

  public getTicketName(): string | null {
    return this.ticketName;
  }

  private initUrl(url: string) {
    const urlSplitted = url.split('/');

    // Asignamos el idioma
    const start = urlSplitted[1];
    const isValidLanguage = Object.values(AppLanguage).includes(
      start as AppLanguage,
    );
    this.start = isValidLanguage ? `/${start}` : null;
    this.language = isValidLanguage ? (start as AppLanguage) : AppLanguage.EN;

    // Se obtienen todas las rutas en el idioma actual.
    const currentLanguageRoutes = Object.entries(i18nRoutes).map((entry) => ({
      id: entry[0],
      value: entry[1][this.language],
      // valueWithoutLanguage: `/${entry[1][this.language][0].split('/')[2]}`,
    }));

    // Se calcula la página a la que referencia la url.
    const page = this.getRoute(currentLanguageRoutes, url);

    this.middle = page ? page.path : null;
    if (this.middle?.endsWith('*')) {
      // cuando termina en "*" es que hay producto en la url vamos a buscarlo sabiendo que es la parte despues de la ultima barra
      this.ticketName = url.split('/').pop() || null;
    }
    this.page = page;
  }

  /**
   * recibimos la lista de rutas de un idioma concreto, y la url a la quiere navegar (path)
   * @param routes
   * @param path
   * @returns
   */
  private getRoute(
    routes: { id: string; value: string[] }[],
    path: string,
  ): { id: PageId; path: string } | null {
    if (!routes || routes.length === 0 || !path) {
      return null;
    }
    //nos quedamos con la ruta que coincida exactamente ( si no hay route será null)
    const route = routes.filter((route) => route.value.includes(path))[0];
    if (route) {
      return { id: route.id as PageId, path: path };
    } else {
      // cuando no se encuetra buscamos el path quitando la parte final (esto es para quitar los tickets)
      const pathSplitted = path.split('/');
      pathSplitted.pop();
      let nextPath = pathSplitted.join('/');

      if (nextPath && !nextPath.endsWith('*')) {
        nextPath += '*';
      }
      // ahora llamamos recursivamente. Esto hace que lleguemos a la raiz si se acumulan segmentos de path sin sentido
      return this.getRoute(routes, nextPath);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class UrlService {
  private state = signal<Url | null>(null);

  public url = computed(() => this.state());

  public setUrl(url: string): void {
    this.state.set(new Url(url));
  }
}
